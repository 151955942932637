import { FormattedMessage } from "react-intl";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import classNames from "classnames";
import { formatRichText, isDesktop, isMobile, stripString } from '../utils';
import Markdown from "markdown-to-jsx";
import { navigate } from "gatsby";
import { useContext } from "react";
import { GlobalDispatchContext } from "../context/GlobalContextProvider";
import MouseHover from "./mouseHover";
import Tags from "./tags";
import Link from "./link";
import { CustomHr } from "./CustomHr";

function CaseItem ({caseStudy, showMobileImages , showParallax, borderRadius, hashtagsBackgroundColor, increaseTagsMobileBottom}) {
   return (
    <>
      <MouseHover 
        caseStudy={caseStudy} 
        gethovered={false} 
        borderRadius={borderRadius}
        classId={`case-list__image nocursor ${caseStudy.tags && caseStudy.tags.length > 0 ? "mb-0" : ""} ${showParallax && "case-list__image--hover"}` } 
      >
        {caseStudy.previewImage && (
          <picture>
            <source media="(min-width: 768px)" srcSet={(showMobileImages ? caseStudy && caseStudy.previewImage && caseStudy.previewImage.url : caseStudy && caseStudy.previewImage && caseStudy.previewImage.url)}  />             
            <source media="(max-width: 767px)" srcSet={(caseStudy && caseStudy.previewImage && caseStudy.previewImage.url )} />
            <img src={(showMobileImages ? caseStudy && caseStudy.previewImage && caseStudy.previewImage.url : caseStudy && caseStudy.previewImage && caseStudy.previewImage.url )} alt={caseStudy && caseStudy.title}  loading="lazy"/>
          </picture>
        )}
      </MouseHover>
      {caseStudy?.tags && caseStudy?.tags?.length > 0 && (
        <Tags 
          tags={caseStudy?.tags?.filter((tag) => tag?.active)} 
          hashtagsBackgroundColor={hashtagsBackgroundColor} 
          borderRadius={borderRadius}
          increaseTagsMobileBottom={increaseTagsMobileBottom}
        />
      )}
    </>

  ) 
}

const CaseList = ({ caseStudies ,oddElementMarginTop, showMobileImages, casesPage, showCaseIntroText, showParallax, imageBorderRadiusForServices, websiteFontColor, showPageTransition, showFullWidthOnMobile, activeDisplay, hashtagsBackgroundColor, pageTransitionBgColor}) => {
 const dispatch = useContext(GlobalDispatchContext);
 const breakpoints = useBreakpoint();
 
 const handleClick = (event, targetUrl, caseStudy, isServices) => {
    event.preventDefault();
    if (!isServices) {
      if (caseStudy?.clientLogo?.logo?.url) {
        dispatch({type: 'SET_SVG_URL', payload: caseStudy.clientLogo.logo.url}); 
      }      
      dispatch({ type: "TOGGLE_SITE_PAGE_TRANSITION" , payload: pageTransitionBgColor && pageTransitionBgColor});
    }
    setTimeout(() => {
      navigate(targetUrl);
    }, 1000)
    // Navigate to the target page
  };


  // Priority items - filter and sort
  const priorityItems = caseStudies.filter(
    caseStudy => caseStudy.slug && caseStudy.slug.length > 0 && caseStudy.showCtaButton
  );

  // Non-priority items - filter and sort
  const nonPriorityItems = caseStudies.filter(
    caseStudy => !priorityItems.includes(caseStudy)
  );

  // Combine the two arrays with priority items first
  const sortedCaseStudies = showCaseIntroText ? [...nonPriorityItems, ...priorityItems] :  caseStudies;
  const hasOneCaseStudy = caseStudies && caseStudies.length === 1 && showCaseIntroText; 
  const showMobileWidthRowClass = breakpoints.sm && showFullWidthOnMobile && !activeDisplay; 

  return (
    <div className={classNames("case-list case-list--small", {"row": showCaseIntroText && breakpoints.sm || showMobileWidthRowClass})}>
      {(caseStudies && caseStudies.length > 0)  && sortedCaseStudies.map((caseStudy, i) => {
        const servicesUrl = caseStudy.isServices && `/${caseStudy && caseStudy.language}/services/${caseStudy && caseStudy.slug}`
        const caseUrl =  `/${caseStudy && caseStudy.language}/case-studies/${caseStudy && caseStudy.slug}`;
        const showCtaButton = caseStudy.showCtaButton;
        const url = servicesUrl ? servicesUrl : caseUrl; 
        const isLeftCase = (i % 2 ) === 0; 
        const fullWidthCase = breakpoints.sm && showCaseIntroText && !showCtaButton ||  hasOneCaseStudy && breakpoints.sm; 
        const { decriptionDisplayText, showDescriptionTextLink } = caseStudy;

        return  (
            <article 
              key={`case-list-item-${i}`} 
              className={classNames(
                {"parallax-item": showParallax}, 
                {"case-list__item ": !showMobileWidthRowClass},
                 `${(fullWidthCase || showCaseIntroText && breakpoints.sm && !fullWidthCase) ? '' : (showMobileWidthRowClass ? "": ( isLeftCase ? 'case-list__item--left' : 'case-list__item--right'))}`, 
                {"case-list__item--small-margin": isDesktop() && oddElementMarginTop && casesPage },
                // {"mb-5 case-list__item--mobile" : !showMobileWidthRowClass},
                {"w-100 col-12 mb-5": fullWidthCase},
                {"col-12 mb-5": showCaseIntroText && breakpoints.sm && !fullWidthCase}, 
                {"col-12 mt-0 case-list__item--box": showMobileWidthRowClass},
                {"no-margin": i === 2},
              )}
              id={stripString(caseStudy.title)}
              >
               {((!showCaseIntroText ||  showCaseIntroText && caseStudy.slug && caseStudy.slug.length > 0 && !decriptionDisplayText ) ) &&  (
                  <>
                   <Link to={url} onClick ={(e) => handleClick(e, url ,  caseStudy, caseStudy.isServices )}>
                    <CaseItem 
                      caseStudy={caseStudy} 
                      showMobileImages={showMobileImages} 
                      showParallax={showParallax}
                      borderRadius={imageBorderRadiusForServices}
                      hashtagsBackgroundColor={hashtagsBackgroundColor}
                      increaseTagsMobileBottom={!showMobileWidthRowClass}
                    />
                    <div className="case-list__content">
                      <h1 className={classNames("case-list__heading case-list__heading--small" , {"case-list__heading--hover": showParallax })}>
                        {caseStudy && caseStudy.title}
                      </h1>
                    </div>
                  </Link>
                  </>
               )} 

              {(showCaseIntroText && decriptionDisplayText && showDescriptionTextLink ) &&  (
                  <>
                   <Link to={url} onClick ={(e) => handleClick(e, url ,  caseStudy, caseStudy.isServices )}>
                    <CaseItem 
                      caseStudy={caseStudy} 
                      showMobileImages={showMobileImages} 
                      showParallax={showParallax}
                      borderRadius={imageBorderRadiusForServices}
                      hashtagsBackgroundColor={hashtagsBackgroundColor}
                      increaseTagsMobileBottom={!showMobileWidthRowClass}
                    />
                    <div className="case-list__content">
                      <h1 className={classNames("case-list__heading case-list__heading--small" , {"case-list__heading--hover": showParallax })}>
                        {caseStudy && caseStudy.title}
                      </h1>
                    </div>
                    {decriptionDisplayText && showDescriptionTextLink && (
                      <div className="rich-text mt-4">
                        <Markdown options={{
                            overrides: {a: {
                              component: Link
                            },                           
                            hr: { 
                                component: CustomHr,
                                props: {
                                  borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                }
                              }  
                            }
                          }}>
                          {formatRichText(decriptionDisplayText || '')}
                        </Markdown>
                      </div>  
                    )}
                  </Link>
                  </>
              )} 
              {(showCaseIntroText && decriptionDisplayText && !showDescriptionTextLink) && (
                <>
                  <div>
                    <CaseItem 
                      caseStudy={caseStudy} 
                      showMobileImages={showMobileImages} 
                      showParallax={showParallax}
                      borderRadius={imageBorderRadiusForServices}
                      hashtagsBackgroundColor={hashtagsBackgroundColor}
                    />
                    <div className="case-list__content">
                      <h1 className={classNames("case-list__heading case-list__heading--small" , {"case-list__heading--hover": showParallax })}>
                        {caseStudy && caseStudy.title}
                      </h1>
                    </div>
                  </div>
                  {decriptionDisplayText && (
                    <div className="rich-text mt-4">
                      <Markdown options={{
                          overrides: {a: {
                            component: Link
                          },                           
                          hr: { 
                              component: CustomHr,
                              props: {
                                borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                              }
                            }  
                          }
                        }}>
                        {formatRichText(decriptionDisplayText || '')}
                      </Markdown>
                    </div>  
                  )}
                </>    
              )}
            </article>
          )
      })}
    </div>
  )
}

CaseList.propTypes = {
  caseStudies: PropTypes.array,
}

CaseList.defaultProps = {
  caseStudies: [],
}

export default CaseList
