import React, {useContext, useEffect, useState} from "react";
import SVG from 'react-inlinesvg';

import Layout from "../components/wrappers/layout"
import SiteHeaderAlt from "../components/wrappers/site-header-alt"
import SEO from "../components/seo"
import Link from "../components/link";
import { domain } from "../utils"
import Header from '../components/header';
import TabbedItem from '../components/wrappers/tabbed-item';
import classNames from "classnames"
import { graphql } from 'gatsby';
import Markdown from 'markdown-to-jsx'
import { getSinglePage } from "../utils";
import { GlobalStateContext } from "../context/GlobalContextProvider";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { CustomHr } from "../components/CustomHr";

const Contact = props => {
  const initialForm = {
    name: '',
    email: '',
    tel: '',
    firm: '',
    message: '',
    agree: false
  }

  const [form, setForm] = useState(initialForm)
  const [status, setStatus] = useState('initial')
  const [errors, setErrors] = useState([])


  const { contact, footers, headers, general } = props.data.strapi; 
  const formLabels = contact.formLabels && contact.formLabels; 
  const { generalBackgroundColorDarkMode,imageBorderRadiusForServices, generalBackgroundColorLightMode, clientLogosColorDarkMode, clientLogosColorLightMode , fontColorDarkMode, fontColorLightMode } = general; 

  const language = props.pageContext.language
  const header = headers && headers.find((header)=>  header.language === language ); 
  const footer = footers && footers.find((footer)=>  footer.language === language ) || {}; 

  const { contacts, reasons, seo, logosHeading, clientlogos, copyright, formHeading } = contact;

  const [displayedLogos, setDisplayedLogos] = useState(clientlogos.slice(0, 8));
  const [availableLogos, setAvailableLogos] = useState(clientlogos.slice(8));
  const [replacedActiveLogoIndex, setLogoReplacedIndex] = useState(undefined);


  const pageFirstHeading = contacts.header && (contacts.header.supheading + " " + contacts.header.heading )
  const seoTitle = seo && seo.metaData.metaTitle || pageFirstHeading|| "Contact us  ";
  const seoDescription = seo && seo.metaData.metaDescription || pageFirstHeading|| "Contact us ";
  const seoKeywords = seo && seo?.metaData?.keywords;
  const allPages = props.data?.allSitePage?.nodes; 
  const webpages = contact && contact.webpages || {};
  const redirectTo = getSinglePage(webpages, allPages); 
  const state = useContext(GlobalStateContext);
  const darkMode = state.darkModePreference === 'moon';
  const greySectionLightModeBgColor = general?.greySectionLightModeBgColor; 
  const greySectionDarkModeBgColor = general?.greySectionDarkModeBgColor; 
  const greySectionBgColor = darkMode ? greySectionDarkModeBgColor : greySectionLightModeBgColor; 
  const sectionBackgroundColor = darkMode ? generalBackgroundColorDarkMode : generalBackgroundColorLightMode; 
  const logosBgColor = darkMode ? clientLogosColorDarkMode : clientLogosColorLightMode; 
  const websiteFontColor = darkMode ? fontColorDarkMode : fontColorLightMode; 
  if (copyright) {
    footer.copyright = copyright;
  }
  
  const handleInputChange = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    const swapLogo = () => {
      if (availableLogos.length > 0) {
        const randomIndex = Math.floor(Math.random() * availableLogos.length);
        const randomLogo = availableLogos[randomIndex];

        const replaceIndex = Math.floor(Math.random() * displayedLogos.length);

        const newDisplayedLogos = [...displayedLogos];
        const [replacedLogo] = newDisplayedLogos.splice(replaceIndex, 1, randomLogo);
        setLogoReplacedIndex(replaceIndex);
        setTimeout(() => {
          setDisplayedLogos(newDisplayedLogos);
          setAvailableLogos([...availableLogos, replacedLogo].filter(logo => logo !== randomLogo));
          setLogoReplacedIndex(undefined);
        }, 300); // Adjust timing as needed
      }
    };

    const intervalId = setInterval(swapLogo, 3000); // Swap every 3 seconds

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [displayedLogos, availableLogos]);


  const handleSubmit = event => {
    event.preventDefault()
    const errors = []

    if (!form.name || form.name === '') {errors.push({id: 'name'})}
    if (!form.email || form.email === '' || !form.email.includes('@') || !form.email.includes('.')) {errors.push({id: 'email'})}
    if (!form.tel || form.tel === '') {errors.push({id: 'tel'})}

    if (errors.length === 0) {
      const target = event.target
      const data = new FormData(target)

      sendForm(data, target.action)
      setErrors([])
    } else {
      setErrors(errors)
    }
  }

  const sendForm = (body, url)=> {
    const xhr = new XMLHttpRequest()
    xhr.open("POST", url)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) { return; }
      if (xhr.status === 200) {
        setForm(initialForm)
        setStatus('success')
      } else {
        // const response = JSON.parse(xhr.response)
        // Only error we get in response is empty form submission... not really sure what else to catch here, suddenly offline?
        setErrors([{id: 'all'}])
      }
    };

    xhr.send(body);
  }

  return (

    <Layout header={header} footer={footer} language={language} redirectTo={redirectTo} isContactPage={true}>
      <SEO
        websiteFontColor={websiteFontColor} 
        template="contact" 
        title={seoTitle} 
        description={seoDescription} 
        keywords={seoKeywords} 
        darkTheme={contact.darkTheme}  
        darkMode={darkMode}
        websiteBackgroundColor={sectionBackgroundColor}
      />
      <SiteHeaderAlt header={header} redirectTo={redirectTo}>
        {contacts && (
          <section className="section section-small-margin" style={{backgroundColor: sectionBackgroundColor}}>
            {contacts.header && (
              <Header heading={contacts.header.heading} supheading={contacts.header.supheading} />
            )}

            <div className="container contact-info">
              {(contacts.contactperson && contacts.contactperson.length > 0) && (
                <div className="row">
                  {contacts.contactperson.map((contactperson, index) => {
                    const isOdd = index % 2;

                    return (
                      <>
                        <div className={'col-4 col-sm-3 col-tw-2 col-lg-1 '+(isOdd ? '' : 'offset-lg-2')}>
                          {contactperson.media && (
                            <img src={contactperson.media.url} alt={contactperson.media.alternativeText} loading="lazy" />
                          )}
                        </div>
                        {contactperson.body && (
                          <div className="col-8 col-sm-9 col-tw-4 col-lg-3 contact-info__body">
                            <h4 className="contact-info__heading">{contactperson.body.heading}</h4>
                            <div className="rich-text">
                              <Markdown options={{
                                overrides: {a: {
                                  component: Link
                                },                           
                                hr: { 
                                    component: CustomHr,
                                    props: {
                                      borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                    }
                                  }  
                                }
                              }}>                                
                                {contactperson.body.richText}
                              </Markdown>
                            </div>
                          </div>
                        )}
                      </>
                    )
                  })}
                </div>
              )}
              <div className="row">
                {contacts?.address?.media && (
                  <div className={'col-4 col-sm-3 col-tw-2 col-lg-1 '+('offset-lg-2')}>
                    {contacts?.address?.media && (
                      <img src={contacts?.address?.media.url} alt={contacts?.address?.media.alternativeText} loading="lazy" />
                    )}
                  </div>
                )}
                {contacts?.address?.heading && (
                  <div className="col-8 col-sm-9 col-tw-4 col-lg-3 contact-info__body">
                    <h4 className="contact-info__heading">{contacts?.address?.heading}</h4>
                    <div className="rich-text">
                      <Markdown options={{
                        overrides: {a: {
                          component: Link
                        },                           
                        hr: { 
                            component: CustomHr,
                            props: {
                              borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                            }
                          }  
                        }
                      }}>                        
                       {contacts?.address?.richText || ""}
                     </Markdown>
                    </div>
                  </div>
                )}
                {contacts?.directions?.media.url && (
                  <div className={'col-4 col-sm-3 col-tw-2 col-lg-1 '}>
                    {contacts?.directions?.media && (
                      <img src={contacts?.directions?.media.url} alt={contacts?.directions?.media.alternativeText} loading="lazy" />
                    )}
                  </div>    
                )}
                {contacts?.directions?.heading && (
                  <div className="col-6 col-tw-4 col-lg-3 contact-info__body">
                    <h4 className="contact-info__heading">{contacts?.directions?.heading}</h4>
                    <div className="rich-text">
                      <Markdown options={{
                        overrides: {a: {
                          component: Link
                        },                           
                        hr: { 
                            component: CustomHr,
                            props: {
                              borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                            }
                          }  
                        }
                      }}>
                        {contacts?.directions?.richText || ''}
                      </Markdown>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        )}

        {reasons && (
          <section className="section" style={{backgroundColor: sectionBackgroundColor}}>
            {reasons.header && (
              <Header heading={reasons.header.heading}  />
            )}

            <div className="container">
              <div className="row">
                <div className="col-tw-6 col-lg-4 offset-lg-2 rich-text">
                  <ul>
                    {reasons.reasons.map((reason, index) => {
                      const isOdd = index % 2;

                      if (isOdd) { return; }

                      return (
                        <li>                  
                          <Markdown options={{
                            overrides: {a: {
                              component: Link
                            },                           
                            hr: { 
                                component: CustomHr,
                                props: {
                                  borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                }
                              }  
                            }
                          }}>
                            {reason.richText}
                          </Markdown>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className="col-tw-6 col-lg-4 rich-text">
                  <ul>
                    {reasons.reasons.map((reason, index) => {
                      const isOdd = index % 2;

                      if (!isOdd) { return; }

                      return (<li>                  
                        <Markdown options={{
                          overrides: {a: {
                            component: Link
                          },                           
                          hr: { 
                              component: CustomHr,
                              props: {
                                borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                              }
                            }  
                          }
                        }}>
                          {reason.richText}
                        </Markdown>
                        </li>
                        )
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </section>
        )}

        {(clientlogos.length && clientlogos.length > 0 ) && (
          <section className="section section--grey" style={{backgroundColor: greySectionBgColor}}>
            {logosHeading && (
              <Header heading={logosHeading || ''} />
            )}
            {clientlogos.length > 0 && (
                <div className="container">
                  <div className="row">
                    <div className="offset-md-1 col-md-10">
                      <ul className="services-logos__list row">
                        {displayedLogos.map((clientLogo, index) => {
                          const logo = clientLogo.logo;
                          return (
                              <li key={index} className="services-logos__item col-6 col-md-3" title={logo.alternativeText} style={logosBgColor === "color" ? {} : {fill: logosBgColor }}>
                                <SVG
                                  className={classNames("services-logos__svg", {"services-logos__svg--rotate": index === replacedActiveLogoIndex })} // Apply animation class conditionally
                                  src={logo.url}
                                />                              
                              </li>
                          );
                        })}
                        {/*{contact.affix && (*/}
                        {/*  <li className="services-logos__item col-6 col-md-4">*/}
                        {/*    <span>{contact.affix}</span>*/}
                        {/*  </li>*/}
                        {/*)}*/}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
          </section>
        )}

        <section className="section">
          {formHeading && (<Header heading={formHeading} />)}
          <div className="container form-wrapper">
            <div className="row">
              {status === 'initial' ? (
                <div className="col-12 col-md-8 offset-md-2">
                  {errors.length > 0 && (
                    <p className="form-error">Bitte füllen Sie alle gekennzeichneten Pflichtfelder aus!</p>
                  )}
                  <form onSubmit={handleSubmit} action="https://formspree.io/mjvevpaa" method="POST" noValidate={true}>
                    <div className="row">
                      <div className="col-12">
                        <div className={classNames({'input-error': errors.find(e => e.id === 'name')})}>
                          <label>Name</label>
                          <input 
                            type="text"
                            value={form.name}
                            name="name"
                            onChange={handleInputChange}/>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className={classNames({'input-error': errors.find(e => e.id === 'email')})}>
                          <label>E-mail</label>
                          <input 
                            type="email"
                            value={form.email}
                            name="email"
                            onChange={handleInputChange}/>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className={classNames({'input-error': errors.find(e => e.id === 'tel')})}>
                          <label>Telefon</label>
                          <input 
                            type="tel"
                            value={form.tel}
                            name="tel"
                            onChange={handleInputChange}/>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className={classNames({'input-error': errors.find(e => e.id === 'firm')})}>
                          <label>Firma</label>
                          <input 
                            type="text"
                            value={form.firm}
                            name="firm"
                            onChange={handleInputChange}/>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className={classNames({'input-error': errors.find(e => e.id === 'message')})}>
                          <label>Nachricht</label>
                          <textarea
                            style={{borderRadius: imageBorderRadiusForServices}}
                            value={form.message}
                            name="message"
                            onChange={handleInputChange}></textarea>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className={classNames({'input-error': errors.find(e => e.id === 'agree')})}>
                          <input 
                            type="checkbox" 
                            id="agree"
                            value={form.agree}
                            name="agree"
                            onChange={handleInputChange}
                            />
                          <label htmlFor="agree"></label>
                          <span>Ich habe die <a href="#">Datenschutz- und Nutzungsbedingungen</a> gelesen und stimme diesen zu.</span>
                        </div>
                      </div>
                      <div className="col-12 text-center">
                        <button type="submit" className="button">Absenden</button>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div className="col-7 offset-2">
                  <p>Tut uns leid, leider gab es bei der Verarbeitung Ihrer Anfrage einen Fehler. Ihre Nachricht konnte deshalb nicht erfolgreich an uns übermittelt werden. Bitte versuchen Sie es erneut oder kontaktieren Sie uns telefonisch.</p>
                  <p>Ihr Team endlazer.</p>
                  <div className="buttons">
                    <button className="button" onClick={() => setStatus('initial')}>Zurück zum Formular</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </SiteHeaderAlt>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Contact($id: ID!, $language: String!) {
    allSitePage {
      nodes {
        path
      }
    }
    strapi {
      general {
        greySectionLightModeBgColor
        greySectionDarkModeBgColor
        generalBackgroundColorDarkMode
        generalBackgroundColorLightMode
        clientLogosColorDarkMode
        clientLogosColorLightMode
        fontColorDarkMode
        fontColorLightMode
        imageBorderRadiusForServices
      }
      headers(where: {language: $language}) {
        ...SiteHeader
      }
      footers(where: { language: $language }) {
        ...SiteFooter
      }
      contact(id: $id) { 
        copyright
        darkTheme   
        logosHeading 
        affix
        clientlogos {
          logo {
            url
            ext
            mime
            alternativeText
          }
        }  
        formHeading
        seo {
          metaData {
            metaTitle
            metaDescription
            keywords
          }
        }
        formLabels {
          email
          phone
          company
          message
          policy
          submit
          name
        }
        contacts {
          header {
            supheading
            heading
          }
          contactperson {
            media {
              url
              alternativeText
              mime
              ext
            }
            body {
              heading
              richText
            }
          }
          address {
            heading
            richText
            media {
              url
              alternativeText
              mime
              ext
            }
          }
          directions {
            heading
            richText
            media {
              url
              alternativeText
              mime
              ext
            }
          }
        }
        reasons {
          header {
            supheading
            heading
          }
          reasons {
            richText
          }
        }
        webpages {
            about_us {
              language
            }
            contact {
              language
            }
            case_study {
              slug
              language
            }
            blogs_overview {
              language
            }
            cases_overview {
              language
            }
            careers_overview {
              language
            }	
            services_overview {
              language
            }
            blog {
              language
              slug
            }
            career {
              language
              slug
            }
            homepage {
              slug
              language
            }
            service_design {
              slug
              language
            }
            service_technical {
              slug
              language
            }
            service_casey_new {
              slug
              language
            }
            service_casey {
              slug
              language
            }
            plainpage {
              language
              slug
            }
          }
      }
    }
    logo: file(relativePath: {eq: "placeholder.jpg"}) {
      childImageSharp {
        fixed(width: 155, height: 105, cropFocus: CENTER) {
          srcSetWebp
          src
        }
      }
    }
  }
`

export default Contact
